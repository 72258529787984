.ka-input-text-no-valid {
  display: block;
  font-size: 14px;
  color: var(--feedback-error-80);
  margin-top: 4px;
}

input[type='text'] {
  border: none;
  border-bottom: 1px outset grey;
  border-style: solid;
}

input[type='email'] {
  border: none;
  border-bottom: 1px solid grey;
}

input[type='password'] {
  border: none;
  border-bottom: 1px solid grey;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='checkbox'] {
  vertical-align: text-bottom;
  margin-right: 10px;
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched {
  border: 1px solid $red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input,
textarea {
  font-size: 1.6rem !important;
}